import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { getApi } from 'apis';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import styles from './index.module.scss';

const ViewByCustomer = () => {
	// const customers = useSelector((state: any) => state.salesReps.details);

	// const [customerOptions, setCustomerOptions] = useState([]);
	// const [selectedCustomer, setSelectedCustomer] = useState(null);
	// const [selectedCustomerData, setSelectedCustomerData] = useState([]);
	const [customersData, setCustomersData] = useState([]);

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [searchValue, setSearchValue] = useState('');

	// console.log('object', selectedCustomerData);

	// const handleCustomer = (e: any) => {
	// 	setSelectedCustomer(e);
	// 	if (customers) {
	// 		const customerData = customers.filter(
	// 			(customer: any) => customer.id === e
	// 		);
	// 		setSelectedCustomerData(customerData);
	// 	}
	// };

	const fetchAllCustomers = async () => {
		try {
			const data = await getApi('/customers', {
				page: currentPage,
				limit: pageSize,
				search: searchValue,
			});
			setCustomersData(data.data.data);
			setTotalRecords(data.data.total);
		} catch (err) {
			console.log('error: ', err);
		}
	};

	const paginationChangeHandler = (value: number) => {
		setCurrentPage(value);
	};

	const pageSizeHandler = (currentPage: number, pageSize: any) => {
		// setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const searchHandler = (value: any) => {
		setCurrentPage(1);
		setSearchValue(value);
	};

	useEffect(() => {
		fetchAllCustomers();
	}, [currentPage, pageSize, searchValue]);

	// useEffect(() => {
	// 	if (customers) {
	// 		const customerOptions = customers.map((customer: any) => {
	// 			return {
	// 				value: customer.id,
	// 				label:
	// 					customer?.firstName || customer?.lastName
	// 						? `${customer?.firstName}  ${customer?.lastName}`
	// 						: customer?.email,
	// 			};
	// 		});
	// 		setCustomerOptions(customerOptions);
	// 	}
	// }, [customers]);

	return (
		<div>
			{/* <Select
				showSearch
				optionFilterProp="label"
				style={{ width: 300, marginBottom: '2rem' }}
				onChange={handleCustomer}
				options={customerOptions}
				value={selectedCustomer}
				placeholder="Select Customer"
			/> */}
			<Space>
				<Input
					className={styles['search']}
					placeholder="Search customer..."
					suffix={<SearchOutlined />}
					onChange={(e) => {
						searchHandler(e.target.value);
					}}
					value={searchValue}
					size="large"
				/>
			</Space>
			<Table
				dataSource={customersData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					onChange: paginationChangeHandler,
					pageSize: pageSize,
					showSizeChanger: true,
					onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				scroll={{ y: 'calc(100vh - 372px)' }}
			>
				<Column
					title="Customer Name"
					dataIndex="customerName"
					key="customerName"
					className="bg-white"
					render={(value, data: any) => {
						return (
							<div>
								{data.firstName || data.lastName
									? `${data.firstName} ${data.lastName}`
									: data.email}
							</div>
						);
					}}
				/>
				<Column
					title="Inside Sales Rep"
					dataIndex="salesrepId"
					key="salesrepId"
					className="bg-white"
					render={(value, data: any) => data.salesrepName}
				/>
				<Column
					title="Outside Sales Rep"
					dataIndex="salesrep2Id"
					key="salesrep2Id"
					className="bg-white"
					render={(value, data: any) => data.salesrep2Name}
				/>
				<Column
					title="Retention Rep"
					dataIndex="retentionSalesRepId"
					key="retentionSalesRepId"
					className="bg-white"
					render={(value, data: any) => data.retentionSalesRepName}
				/>
			</Table>
		</div>
	);
};

export default ViewByCustomer;
